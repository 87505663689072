import { Injectable } from '@angular/core';
import { User } from '../../core/models/user';

@Injectable({
    providedIn: 'root',
})
export class RbacService {
    private _roles: string[] = [];
    private _authenticatedUser!: User;

    setRoles(roles: string[]) {
        this._roles = roles;
    }

    setAuthenticatedUser(user: User) {
        this._authenticatedUser = user;
    }

    isGranted(roleOrPermission: string, user?: User): boolean {
        let userData = null;
        if (!user) userData = this._authenticatedUser;
        return userData.role === roleOrPermission;
    }
}
